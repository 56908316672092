import * as React from "react";
import PropTypes from "prop-types";
import { graphql, useStaticQuery } from "gatsby";

import { Layout, HeroPost, SectionGrid } from "../components/common";
import { MetaData } from "../components/common/meta";

import * as homeStyles from '../styles/home.module.css'

/**
 * Main index page (home page)
 *
 * Loads all posts from Ghost and uses pagination to navigate through them.
 * The number of posts that should appear per page can be setup
 * in /utils/siteConfig.js under `postsPerPage`.
 *
 */
const Index = ({ location }) => {
    const data = useStaticQuery(graphql`
    query AllPosts
    {
        allGhostPost {
            edges {
                node {
                    ...GhostPostFields
                }
            }
        }
    }
`)
    const posts = data.allGhostPost.edges;

    return (
        <>
            <MetaData location={location} />
            <Layout>
                <div className={homeStyles.home}>
                    <HeroPost />
                    <SectionGrid tag="opinion" posts={posts} context={'home'} />
                    <SectionGrid tag="wikihow" posts={posts} context={'home'} />
                    <SectionGrid tag="canal" posts={posts} context={'home'} />
                    {/* <Pagination pageContext={pageContext} /> */}
                </div>
            </Layout>
        </>
    );
};

Index.propTypes = {
    data: PropTypes.shape({
        allGhostPost: PropTypes.object.isRequired,
    }),
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
    pageContext: PropTypes.object,
};

export default Index;